<template>
<div>

    <v-row>
        <v-col cols="12">
            <s-toolbar dark label="Analisis de Venta" class=""></s-toolbar>
            <v-card outlined>

                <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                    <v-col cols="12" lg="6" md="6">
                        <v-row>
                            <v-col cols="12" style="margin-top:-20px">
                                <v-card-text>
                                    <h3 style="font-family:Calibri">
                                        Etapa Comercial
                                    </h3>
                                    <v-chip-group v-model="amenities" column multiple>
                                        <v-chip small filter outlined v-model="check.separacion">
                                            SEPARACION
                                        </v-chip>
                                        <v-chip small filter outlined v-model="check.separaciontemporal">
                                            SEPARACION TEMPORAL
                                        </v-chip>
                                        <v-chip small filter outlined v-model="check.venta">
                                            VENTA
                                        </v-chip>
                                    </v-chip-group>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" style="margin-top:-30px">
                                <v-card outlined>
                                    <v-data-table :fixed-header="true" outlined :headers="headersLot" :items="itemsLot" dense :items-per-page="-1" hide-default-footer>
                                    </v-data-table>
                                </v-card>

                            </v-col>
                            <v-divider class="mt-2" horizontal></v-divider>

                            <v-col cols="12">
                                <v-card outlined>
                                    <v-data-table :headers="headersArea" :items="itemsArea" dense :items-per-page="-1" hide-default-footer>
                                    </v-data-table>
                                </v-card>
                            </v-col>

                            <v-divider horizontal></v-divider>

                            <v-col cols="12">
                                <v-card outlined>
                                    <v-data-table :headers="headersCapital" :items="itemsCapital" dense :items-per-page="-1" hide-default-footer>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <clc-analysis-dasboard :items="dataDashboard"></clc-analysis-dasboard>
                    </v-col>

                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn @click="initializeSession" color="info">Buscar</v-btn>
        </v-col>
        <v-col cols="12">
            <v-textarea auto-grow color="black" label="JSON" v-model="json"></v-textarea>
        </v-col>

    </v-row>
</div>
</template>

<script>
import _sRepoClicks from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcAnalysisDasboard from './Dashboard/ClcAnalysisDasboard.vue';

export default {
    components: {
        ClcAnalysisDasboard
    },

    data() {
        return {
            headersLot: [],
            itemsLot: [],
            headersArea: [],
            itemsArea: [],
            headersCapital: [],
            itemsCapital: [],
            amenities: [0, 1, 2],
            check: {
                separacion: null,
                separaciontemporal: null,
                venta: null
            },
            dataDashboard: [],
            groupAll: [],
            itemsGraphLine: [],
            json: null
        }
    },

    watch: {

        amenities() {
            this.initializeData()
        }
    },

    created() {

        this.initializeData()

    },

    methods: {

        initializeData() {

            var obj = {
                EtapaComercial: this.amenities.toString()
            }

            _sRepoClicks.listAnalysisSale(obj, this.$fun.getUserID()).then(resp => {
                this.itemsLot = []
                this.itemsArea = []
                this.itemsCapital = []
                this.itemsGraphLine = []
                this.headersLot = []
                this.headersArea = []
                this.headersCapital = []

                this.itemsLot = resp.data.itemsLot
                this.itemsArea = resp.data.itemsArea
                this.itemsCapital = resp.data.itemsCapital
                this.itemsGraphLine = resp.data.itemsGraphLine

                console.log(this.itemsLot.filter(x => x.MES_AJUST == 'Total general')[0])
                this.itemsLot.forEach(element => {

                    if (element.Separacion == 0 && this.itemsLot.filter(x => x.MES_AJUST == 'Total general')[0].Separacion == 0) {
                        delete(element.Separacion)
                    }
                    if (element.SeparacionTemporal == 0 && this.itemsLot.filter(x => x.MES_AJUST == 'Total general')[0].SeparacionTemporal == 0) {
                        delete(element.SeparacionTemporal)
                    }
                    if (element.Venta == 0 && this.itemsLot.filter(x => x.MES_AJUST == 'Total general')[0].Venta == 0) {
                        delete(element.Venta)
                    }
                });

                this.itemsArea.forEach(element => {
                    if (element.Separacion == 0 && this.itemsArea.filter(x => x.MES_AJUST == 'Total general')[0].Separacion == 0) {
                        delete(element.Separacion)
                    }
                    if (element.SeparacionTemporal == 0 && this.itemsArea.filter(x => x.MES_AJUST == 'Total general')[0].SeparacionTemporal == 0) {
                        delete(element.SeparacionTemporal)
                    }
                    if (element.Venta == 0 && this.itemsArea.filter(x => x.MES_AJUST == 'Total general')[0].Venta == 0) {
                        delete(element.Venta)
                    }
                });

                this.itemsCapital.forEach(element => {
                    if (element.Separacion == 0 && this.itemsCapital.filter(x => x.MES_AJUST == 'Total general')[0].Separacion == 0) {
                        delete(element.Separacion)
                    }
                    if (element.SeparacionTemporal == 0 && this.itemsCapital.filter(x => x.MES_AJUST == 'Total general')[0].SeparacionTemporal == 0) {
                        delete(element.SeparacionTemporal)
                    }
                    if (element.Venta == 0 && this.itemsCapital.filter(x => x.MES_AJUST == 'Total general')[0].Venta == 0) {
                        delete(element.Venta)
                    }
                });

                for (
                    var i = 0; i < Object.keys(this.itemsLot[0]).length; i++
                ) {
                    this.headersLot.splice(i, i, {
                        text: Object.keys(this.itemsLot[0])[i],
                        value: Object.keys(this.itemsLot[0])[i],
                        width: "70",
                        sortable: false,
                    });
                }

                for (
                    var i = 0; i < Object.keys(this.itemsArea[0]).length; i++
                ) {
                    this.headersArea.splice(i, i, {
                        text: Object.keys(this.itemsArea[0])[i],
                        value: Object.keys(this.itemsArea[0])[i],
                        width: "70",
                        sortable: false,
                    });
                }

                for (
                    var i = 0; i < Object.keys(this.itemsCapital[0]).length; i++
                ) {
                    this.headersCapital.splice(i, i, {
                        text: Object.keys(this.itemsCapital[0])[i],
                        value: Object.keys(this.itemsCapital[0])[i],
                        width: "70",
                        sortable: false,
                    });
                }

                //********************************************** PARA GRAFICOS */
                var objTmp = {},
                    j = 0,
                    value = 0,
                    valueName = ""
                this.dataDashboard = []
                this.groupAll = _.groupBy(this.itemsGraphLine, "DIA_AJUST");
                for (let prop in this.groupAll) {

                    objTmp = {}
                    j = 0
                    value = 0
                    valueName = ""

                    this.groupAll[prop].map((i) => {
                        j = j + i.Cantidad
                        value = i.MesNumero
                        valueName = i.MesName
                    });

                    //GRAFICO BAR
                    objTmp.name = prop
                    objTmp.y = j
                    objTmp.value = value
                    objTmp.valueName = valueName
                    /* objTmp.custom = { value: value } */

                    this.dataDashboard.push(objTmp)

                    this.dataDashboard.sort((a, b) => {
                        return a.value - b.value;
                    });
                }
            })

        },

        consumirApi() {
            var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfbmFtZSI6IlRlc3RNaXJhZmxvcmVzV0EiLCJlbXByZXNhIjoiMTUxIiwiaWQiOiJUZXN0TWlyYWZsb3Jlc1dBIiwicm9sZSI6InVzZXIiLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0LyIsImF1ZCI6IkFueSIsImV4cCI6MTY2MjU2Mzg0MCwibmJmIjoxNjYyNTYyMDQwfQ.gEJmI0-bJBQqwpRMV9FDEZE9ZuNXIHN8HLwOl_7mtjo";
            var url = "https://webapi.evolta.pe/api/prospecto?proyecto=1564&prospecto=&responsable=&estado=&FechaIni=&FechaFin=&FechaCitadoIni=&FechaCitadoFin=";
            //var url = "https://webapi.evolta.pe/api/proyecto";
            _sRepoClicks.getProspect(token, url, this.$fun.getUserID()).then(resp => {
                var arr_from_json = JSON.parse(resp.data);
                this.json = arr_from_json
                console.log(arr_from_json)
            })
        },

        initializeSession() {
            _sRepoClicks.initializeSession(this.$fun.getUserID()).then(resp => {

                console.log(resp)
            })
        }

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
